import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';

@Component({
  selector: 'app-success',
  standalone: true,
  imports: [RouterModule, MatCardModule, MatButtonModule],
  template: `
    <mat-card class="mat-elevation-z1" style="max-width: 800px; margin: auto; padding: 20px;">
      <p style="margin-bottom: 20px;">
        Thank you for your interest in Best in Class Education Center. Please check your email for submission details. See the Center Manager to continue registration for your student(s).
      </p>
      <div class="button-container">
        <button mat-flat-button color="primary" (click)="registerAnother()">Register Another</button>
      </div>
    </mat-card>
  `,
  styles: `
    .mdc-button {
      border-radius: 4px;
    }
    .button-container {
      display: flex;
      justify-content: flex-start;
    }
    .mat-flat-button {
      min-width: 150px;
    }
  `
})
export class SuccessComponent implements OnInit {
  centerId!: string;
  constructor(private router: Router, private activatedRoute: ActivatedRoute,) { }

  ngOnInit(): void { this.centerId = this.activatedRoute.snapshot.params['centerId']; }

  registerAnother(): void {
    this.router.navigate(['/', this.centerId]);
  }
}
