import { Component, OnInit } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { RegistrationService } from '../../services/registration.service';
import { CommonModule } from '@angular/common';
import { ILocation } from '../../models/ILocation';
import { MatButtonModule } from '@angular/material/button';
import { IState } from '../../models/IState';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-state-list',
  standalone: true,
  imports: [MatCardModule, MatChipsModule, MatProgressBarModule, CommonModule, MatButtonModule, RouterLink],
  templateUrl: './state-list.component.html',
  styleUrl: './state-list.component.scss'
})

export class StateListComponent implements OnInit {
  centers: Array<ILocation> = new Array<ILocation>;
  states: Array<IState> = new Array<IState>;
  selectedStateId: string = "";
  filteredCenters: Array<ILocation> = new Array<ILocation>;

  constructor(
    private regService: RegistrationService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { };

  async ngOnInit(): Promise<void> {
    this.selectedStateId = this.activatedRoute.snapshot.params["stateId"];

    var self = this;

    try {
      const response = await firstValueFrom(this.regService.getJSONLocations());
      this.centers = response.body!;
    } catch (error) {
      console.error('Error fetching locations:', error);
    }

    if (this.centers) {
      this.centers.forEach(
        function (x: {
          stateFullName: any; state: any;
        }) {
          if (self.states.length == 0 || self.states.findIndex(y => y.state == x.state) == -1)
            self.states.push({ state: x.state, stateFullName: x.stateFullName, selected: false });
        });

      this.states.sort(function (a, b) {
        if (a.stateFullName < b.stateFullName) return -1
        if (a.stateFullName > b.stateFullName) return 1
        return 0
      })

      if (this.selectedStateId) {
        this.selectedState(this.selectedStateId);
      }
    }

  };

  selectedState(st: string): void {
    this.states.forEach(state => {
      state.selected = false;

      if (state.state == st) {
        state.selected = true;
      }
    });
    this.filteredCenters = this.centers.filter((center) => center.state == st);
  }
}
