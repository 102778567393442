/// <reference types="google.maps" />

import { CommonModule, isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID, Input, NgZone } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { Globals } from '../../data/globals';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-google-places',
  standalone: true,
  imports: [
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    CommonModule,
    MatSelectModule,
    ReactiveFormsModule
  ],
  templateUrl: './google-places.component.html',
  styleUrls: ['./google-places.component.scss']
})
export class GooglePlacesComponent implements OnInit {
  @Input() addressForm!: FormGroup;

  autocomplete!: google.maps.places.Autocomplete;
  states!: string[];
  isBrowser: boolean;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private ngZone: NgZone
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  ngOnInit(): void {
    this.states = Globals.States;

    if (this.isBrowser) {
      const streetInput = document.getElementById('street') as HTMLInputElement;

      this.autocomplete = new google.maps.places.Autocomplete(streetInput, {
        componentRestrictions: { country: ['us', 'ca'] },
        fields: ['address_components', 'geometry'],
        types: ['address']
      });

      this.autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          this.fillInAddress();
        });
      });
    }
  }

  fillInAddress(): void {
    const place = this.autocomplete.getPlace();

    let street_number = '';
    let route = '';
    let city = '';
    let state = '';
    let zip = '';

    for (const component of place.address_components ?? []) {
      const componentType = component.types[0];

      switch (componentType) {
        case 'street_number': {
          street_number = component.long_name;
          break;
        }
        case 'route': {
          route = component.short_name;
          break;
        }
        case 'locality': {
          city = component.long_name;
          break;
        }
        case 'administrative_area_level_1': {
          state = component.short_name;
          break;
        }
        case 'postal_code': {
          zip = component.long_name;
          break;
        }
        case "postal_code_suffix": {
          zip = `${zip}-${component.long_name}`;
          break;
        }
      }
    }

    const street = `${street_number} ${route}`.trim();

    this.addressForm.patchValue({
      street: street,
      city: city,
      state: state,
      zip: zip
    });
  }
}
