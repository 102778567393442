import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})

export class HeaderComponent implements OnInit {
  version!: string;
  copyright!: string;
  envName!: string;

  constructor() { }

  ngOnInit(): void {
    this.version = environment.release_version;
    this.copyright = environment.copyright;
    this.envName = environment.env_name;
  }
}
