<div class="container-fluid mb-20" [formGroup]="form">
  <div class="row justify-content-center">
    <div class="col-10">
      <mat-card class="custom-card mb-2" appearance="outlined">
        <input type="hidden" formControlName="answer">
        <!-- Conditional Card -->
        <mat-card class="p-3 mb-2" *ngIf="isPracticeCenter">
          <div class="row">
            <div class="col-12">
              <mat-form-field class="full-width" appearance="fill">
                <mat-label>Practice Student Data</mat-label>
                <mat-select [formControl]="practiceDataControl" (selectionChange)="prefillData($event.value)">
                  <mat-option *ngFor="let student of practiceData" [value]="student">
                    {{ student.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </mat-card>

        <!-- Student section -->
        <ng-container formArrayName="students">
          <ng-container *ngFor="let student of students.controls; let i = index; trackBy: trackByIndex">
            <div [formGroupName]="i">
              <mat-card-header>
                <mat-card-title>Student Information
                  <span *ngIf="students.length > 1">{{ i + 1 }}</span>
                  <button *ngIf="i > 0" mat-icon-button class="delete-button" color="warn" (click)="deleteStudent(i)">
                    <mat-icon>delete</mat-icon>
                  </button>
                </mat-card-title>
              </mat-card-header>
              <mat-card-content class="mb-3">
                <div class="row">
                  <div class="col-md-6 col-sm-12">
                    <mat-form-field class="full-width" appearance="fill">
                      <mat-label>First name</mat-label>
                      <input matInput formControlName="firstName" required>
                      <mat-error *ngIf="student.get('firstName')?.hasError('required')">
                        First name is required
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-md-6 col-sm-12">
                    <mat-form-field class="full-width" appearance="fill">
                      <mat-label>Last name</mat-label>
                      <input matInput formControlName="lastName" required>
                      <mat-error *ngIf="student.get('lastName')?.hasError('required')">
                        Last name is required
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3 col-sm-6">
                    <mat-form-field class="full-width" appearance="fill">
                      <mat-label>Gender</mat-label>
                      <mat-select formControlName="gender">
                        <mat-option *ngFor="let gender of genders" [value]="gender">{{gender}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col-md-3 col-sm-6">
                    <mat-form-field class="full-width" appearance="fill">
                      <mat-label>School</mat-label>
                      <input matInput formControlName="school">
                    </mat-form-field>
                  </div>
                  <div class="col-md-3 col-sm-6">
                    <mat-form-field class="full-width" appearance="fill">
                      <mat-label>Date Of Birth</mat-label>
                      <input matInput [matDatepicker]="picker" formControlName="dob">
                      <mat-hint>MM/DD/YYYY</mat-hint>
                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                  </div>
                  <div class="col-md-3 col-sm-6">
                    <mat-form-field class="full-width" appearance="fill">
                      <mat-label>Grade</mat-label>
                      <mat-select formControlName="grade" required>
                        <mat-option *ngFor="let grade of grades" [value]="grade">{{grade}}</mat-option>
                      </mat-select>
                      <mat-error *ngIf="student.get('grade')?.hasError('required')">
                        Grade is required
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </mat-card-content>
            </div>
          </ng-container>
        </ng-container>

        <div class="row">
          <div class="col-12">
            <button mat-raised-button class="text-right" (click)="addStudent()">Add another student</button>
          </div>
        </div>

        <!-- Address section -->
        <mat-card-header>
          <mat-card-title>Address</mat-card-title>
        </mat-card-header>
        <mat-card-content class="mb-3" formGroupName="address">
          <div class="row">
            <app-google-places [addressForm]="address"></app-google-places>
          </div>
        </mat-card-content>

        <!-- Guardian section -->
        <mat-card-header>
          <mat-card-title>Parent/Guardian Information</mat-card-title>
        </mat-card-header>
        <mat-card-content class="mb-3">
          <div class="row" formArrayName="parents">
            <div class="col-md-6 col-sm-12" *ngFor="let parent of parents.controls; let i = index; trackBy: trackByIndex">
              <div [formGroupName]="i">
                <mat-card-subtitle class="mb-2">Parent/Guardian {{i + 1}}</mat-card-subtitle>
                <div class="row">
                  <div class="col-8">
                    <mat-form-field class="full-width" appearance="fill">
                      <mat-label>First and Last Name</mat-label>
                      <input matInput formControlName="name" [required]="i === 0">
                      <mat-error *ngIf="parent.get('name')?.hasError('required')">
                        Parent's name is required
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-4">
                    <mat-form-field class="full-width" appearance="fill">
                      <mat-label>Relationship</mat-label>
                      <mat-select formControlName="relationship">
                        <mat-option *ngFor="let relation of relationships" [value]="relation">{{relation}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col-8">
                    <mat-form-field class="full-width" appearance="fill">
                      <mat-label>Phone</mat-label>
                      <input matInput formControlName="phone" [required]="i === 0" appPhoneNumber>
                      <mat-error *ngIf="parent.get('phone')?.hasError('required')">
                        Phone number is required
                      </mat-error>
                      <mat-error *ngIf="parent.get('phone')?.hasError('invalidPhoneNumber')">
                        Invalid phone number (e.g.123-456-7890)
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <div class="col-4">
                    <mat-form-field class="full-width" appearance="fill">
                      <mat-label>Type</mat-label>
                      <mat-select formControlName="phoneType">
                        <mat-option *ngFor="let phoneType of phoneTypes" [value]="phoneType">{{phoneType}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <mat-form-field class="full-width" appearance="fill">
                      <mat-label>Email</mat-label>
                      <input matInput formControlName="email" [required]="i === 0">
                      <mat-error *ngIf="parent.get('email')?.hasError('required')">
                        Email is required
                      </mat-error>
                      <mat-error *ngIf="parent.get('email')?.hasError('email')">
                        Invalid email address
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-card-content>

        <!-- Referred section -->
        <mat-card-content>
          <div class="row">
            <div class="col-md-6 col-sm-12">
              <mat-form-field class="full-width" appearance="fill">
                <mat-label>Referred by</mat-label>
                <mat-select formControlName="referredBy">
                  <mat-option *ngFor="let source of referredBySources" [value]="source">{{source}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-sm-12">
              <mat-checkbox formControlName="policyAgreement" required>
                <mat-label class="mat-check-title">I acknowledge that I have read and understood the &nbsp;</mat-label>
                <a (click)="openDialog()" class="text-primary link-tag mat-check-title">Rules and Policies*</a>
              </mat-checkbox>
              <mat-error class="mat-check-error"
                *ngIf="form.get('policyAgreement')?.invalid && (form.get('policyAgreement')?.touched || formSubmitted)">
                This field is required
              </mat-error>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-sm-12">
              <app-signature-pad (signature)="signed($event)"></app-signature-pad>
            </div>
          </div>
        </mat-card-content>

        <!-- Form submission buttons -->
        <div class="row justify-content-end mt-3">
          <div class="col-md-4">
            <button mat-stroked-button class="text-right" (click)="clearForm()">Clear form</button>
            <button mat-flat-button class="text-right mr-10" (click)="submitForm()"
              [disabled]="form.invalid">Submit</button>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
</div>
