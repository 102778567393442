import { ChangeDetectionStrategy, Component, Inject, inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule } from '@angular/material/dialog';
import { IPolicy } from '../../models/IPolicy';

@Component({
  selector: 'app-dialog',
  standalone: true,
  imports: [MatButtonModule, MatDialogModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './dialog.component.html',
  styleUrl: './dialog.component.scss'
})

export class DialogComponent implements OnInit {
  title!: string;
  content!: string;
  hideCancelButton: boolean = true;
  hideSubmitButton: boolean = true;
  cancelButtonText: string = "Cancel";
  submitButtonText: string = "Submit";

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {

  };

  ngOnInit(): void {
    this.title = this.data.title;
    this.content = this.data.content;
    this.hideCancelButton = this.data.hideCancelButton ?? this.data.hideCancelButton;
    this.hideSubmitButton = this.data.hideSubmitButton ?? this.data.hideSubmitButton;
    this.cancelButtonText = this.data.cancelButtonText ?? this.data.cancelButtonText;
    this.submitButtonText = this.data.submitButtonText ?? this.data.submitButtonText;
  }
}
