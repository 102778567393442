<div class="container-fluid">
    <div class="row justify-content-center">
        <a mat-raised-button class="state-button {{st.selected ? 'state-active' : ''}}" *ngFor="let st of states"
            (click)="selectedState(st.state)" routerLink="/states/{{st.state}}">
            {{st.stateFullName}}
        </a>
    </div>
    <div class="row justify-content-center">
        <div class="col-10">
            <div class="row">
                <div class="col-md-3" *ngFor="let location of filteredCenters">
                    <a [routerLink]="['/registration/', location.slug]" mat-raised-button class="location-button">
                        <p class="location-name">{{location.name}}</p>
                        <span class="location-address">{{location.address}} <br /> {{location.city}}
                            {{location.state}}
                            {{location.zip}}</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>