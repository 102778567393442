export interface Student {
  firstName: string;
  lastName: string;
  school: string;
  grade: string;
  gender: string;
  dob: string;
}

export interface Parent {
  name: string;
  relationship: string;
  phone: string;
  phoneType: string;
  email?: string;
}

export interface Address {
  address1: string;
  city: string;
  state: string;
  zip: string;
}

export interface PracticeDataItem {
  students: Student[];
  address: Address;
  parents: Parent[];
  name?: string;
}

export const practiceData: PracticeDataItem[] = [
  {
    students: [
      {
        firstName: 'Nicolas',
        lastName: 'Berlage',
        school: 'Cedar Crest',
        grade: '6',
        gender: 'Male',
        dob: '2002-07-24T00:00:00.000Z',
      },
    ],
    address: {
      address1: '24334 SE 39th Pl',
      city: 'Issaquah',
      state: 'WA',
      zip: '98029',
    },
    parents: [
      {
        name: 'Yizu Berlange',
        relationship: 'Mom',
        phone: '425-890-7822',
        phoneType: 'Mobile',
      },
    ],
  },
  {
    students: [
      {
        firstName: 'Ela',
        lastName: 'Smith',
        school: 'Cherry Crest',
        grade: '2',
        gender: 'Female',
        dob: '2006-04-01T00:00:00.000Z',
      },
      {
        firstName: 'Derin',
        lastName: 'Smith',
        school: 'Cherry Crest',
        grade: 'K',
        gender: 'Male',
        dob: '2008-10-09T00:00:00.000Z',
      },
    ],
    address: {
      address1: '10243 NE 27th Pl',
      city: 'Bellevue',
      state: 'WA',
      zip: '98006',
    },
    parents: [
      {
        name: 'Gulin Smith',
        relationship: 'Mom',
        phone: '425-639-8141',
        phoneType: 'Mobile',
      },
    ],
  },
  {
    students: [{
      firstName: "Arnav",
      lastName: "Ganesh",
      school: "Tillicum",
      grade: "7",
      gender: "Male",
      dob: "2000-12-26T00:00:00.000Z",
    }],
    address: {
      address1: "16120 NE 10th St. Apt. 1102",
      city: "Bellevue",
      state: "WA",
      zip: "98008",
    },
    parents: [{
      name: "Nilesh Ganesh",
      relationship: "Dad",
      phone: "206-321-0879",
      phoneType: "Mobile",
    }]
  },
  {
    students: [{
      firstName: "Shreya",
      lastName: "Kumar",
      school: "Somerset El",
      grade: "3",
      gender: "Female",
      dob: "2005-05-24T00:00:00.000Z",
    }, {
      firstName: "Aarushi",
      lastName: "Kumar",
      school: "Somerset El",
      grade: "K",
      gender: "Male",
      dob: "2008-03-09T00:00:00.000Z",
    }],
    address: {
      address1: "1717 150th Ave SE #7",
      city: "Bellevue",
      state: "WA",
      zip: "98006",
    },
    parents: [{
      name: "Jaya Kumar",
      relationship: "Mom",
      phone: "425-234-0801",
      phoneType: "Mobile",
    }, {
      name: "Sreedhar Kumar",
      relationship: "Dad",
      phone: "425-801-0802",
      phoneType: "Mobile",
    }]
  },
  {
    students: [{
      firstName: "Randy",
      lastName: "Do",
      school: "Tyee Middle School",
      grade: "6",
      gender: "Male",
      dob: "2001-03-14T00:00:00.000Z",
    }],
    address: {
      address1: "13281 NE 133th St, Apt 1H",
      city: "Kirkland",
      state: "WA",
      zip: "98034",
    },
    parents: [{
      name: "Jack Do",
      relationship: "Dad",
      phone: "206-123-4567",
      phoneType: "Mobile",
    }],
  },
  {
    students: [{
      firstName: "Anirudh",
      lastName: "Johns",
      school: "BCA",
      grade: "6",
      gender: "Male",
      dob: "2003-05-09T00:00:00.000Z",
    }],
    address: {
      address1: "3014 200th Pl SE",
      city: "Bothell",
      state: "WA",
      zip: "98012",
    },
    parents: [{
      name: "Ganesh Johns",
      relationship: "Dad",
      phone: "425-233-4606",
      phoneType: "Mobile",
    }],
  }
];

// Generate the 'name' property for each item
for (let item of practiceData) {
  item.name = item.students.reduce((name, student) => {
    const studentName = `${student.firstName} ${student.lastName}`;
    return name ? `${name} and ${studentName}` : studentName;
  }, '');
}
