import { CommonModule, isPlatformBrowser } from '@angular/common';
import { Component, ElementRef, Inject, Input, OnInit, PLATFORM_ID, ViewChild, Output, EventEmitter, HostListener } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import SignaturePad from 'signature_pad';

@Component({
  selector: 'app-signature-pad',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatFormFieldModule],
  templateUrl: './signature-pad.component.html',
  styleUrl: './signature-pad.component.scss'
})
export class SignaturePadComponent {
  @Output() signature = new EventEmitter<string>();

  signaturePad!: SignaturePad;
  @ViewChild('canvas') canvasEl!: ElementRef;
  isBrowser: boolean;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  ngAfterViewInit(): void {
    if (this.isBrowser) {
      this.signaturePad = new SignaturePad(this.canvasEl.nativeElement);
      this.resizeSignaturePad();
    }
  }

  startDrawing(event: Event) {
    // works in device not in browser
  }

  moved(event: Event) {
    // works in device not in browser
    const base64Data = this.signaturePad.toDataURL();
    this.signature.emit(base64Data);
  }

  savePad() {
    const base64Data = this.signaturePad.toDataURL();
    this.signature.emit(base64Data);
  }

  clearPad() {
    this.signaturePad.clear();
  }

  @HostListener('window:resize')
  onResize() {
    this.resizeSignaturePad();
  }

  private resizeSignaturePad() {
    const canvas = this.canvasEl.nativeElement;

    // Set canvas width and height to 100% of the container's size
    canvas.width = canvas.parentElement?.clientWidth || window.innerWidth;
    canvas.height = canvas.parentElement?.clientHeight || window.innerHeight;

    // Clear the canvas to avoid stretched drawings after resizing
    this.signaturePad.clear();
  }
}
