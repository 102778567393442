import { Directive, HostListener, forwardRef, ElementRef, Renderer2 } from '@angular/core';
import {
  NG_VALUE_ACCESSOR,
  ControlValueAccessor,
  NG_VALIDATORS,
  Validator,
  AbstractControl,
  ValidationErrors
} from '@angular/forms';

@Directive({
  selector: '[appPhoneNumber]',
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PhoneNumberDirective),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PhoneNumberDirective),
      multi: true
    }
  ]
})
export class PhoneNumberDirective implements ControlValueAccessor, Validator {
  private onChange: (value: string) => void = () => { };
  private onTouched: () => void = () => { };

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  writeValue(value: any): void {
    this.renderer.setProperty(this.el.nativeElement, 'value', value || '');
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.renderer.setProperty(this.el.nativeElement, 'disabled', isDisabled);
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (value && !this.isValidPhoneNumber(value)) {
      return { invalidPhoneNumber: true };
    }
    return null;
  }

  @HostListener('input', ['$event.target.value'])
  onInput(value: string): void {
    const formatted = this.formatPhoneNumber(value);
    this.renderer.setProperty(this.el.nativeElement, 'value', formatted);
    this.onChange(formatted);
  }

  @HostListener('blur')
  onBlur(): void {
    this.onTouched();
  }

  private formatPhoneNumber(value: string): string {
    const digits = value.replace(/\D/g, '');

    let formatted = digits;

    if (digits.length > 3 && digits.length <= 6) {
      formatted = `${digits.substring(0, 3)}-${digits.substring(3)}`;
    } else if (digits.length > 6) {
      formatted = `${digits.substring(0, 3)}-${digits.substring(3, 6)}-${digits.substring(6, 10)}`;
    }

    return formatted;
  }

  private isValidPhoneNumber(value: string): boolean {
    const phoneRegex = /^\d{3}-\d{3}-\d{4}$/;
    return phoneRegex.test(value);
  }
}
