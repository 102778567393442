<div class="signature-container">
  <canvas
    #canvas
    (touchstart)="startDrawing($event)"
    (touchmove)="moved($event)"
    (mouseover)="savePad()"
    class="pad"
  ></canvas>
</div>

<div class="buttons flex justify-between">
    <button mat-button (click)="clearPad()">Clear your signature</button>
  </div>
