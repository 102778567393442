import { Routes } from '@angular/router';
import { StateListComponent } from './pages/state-list/state-list.component';
import { SubmitFormComponent } from './pages/submit-form/submit-form.component';
import { SuccessComponent } from './pages/success/success.component';

export const routes: Routes = [
    {path: '', component: StateListComponent},
    {path: 'reg', redirectTo: '', pathMatch: 'full' },
    {path: 'reg/:centerId', component: SubmitFormComponent },
    {path: 'registration', component: StateListComponent},
    {path: 'states/:stateId', component: StateListComponent},
    {path: 'registration/:centerId', component: SubmitFormComponent},
    {path: ':centerId', component: SubmitFormComponent},
    {path: ':centerId/success', component: SuccessComponent},
];
