import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { ILocation } from '../models/ILocation';
import { IPolicy } from '../models/IPolicy';
import { IDataForm } from '../models/IDataForm';
import { environment } from '../../environments/environment';
import { catchError, map, Observable, tap, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {

  private policyAvailableList = ["katy-cinco-ranch", "katy-mason", "pleasanton", "south-orange"];
  private _dataUrl: string = '/assets/data/locations.json';
  private apiUrl: string = "";
  private locations!: Array<ILocation>;
  private policy!: IPolicy;
  // handleError: any;

  constructor(private http: HttpClient) {
    this.apiUrl = environment.apiUrl;
  }

  getJSONLocations(): Observable<HttpResponse<Array<ILocation>>> {
    return this.http.get<Array<ILocation>>(this._dataUrl, { observe: 'response' });
  }

  getPolicy(centerId: string | null): Observable<HttpResponse<IPolicy>> {
    centerId = centerId && this.policyAvailableList.indexOf(centerId) !== -1 ? centerId : "default";
    var policyPath = "/assets/policies/policies-" + centerId + ".json";

    return this.http.get<IPolicy>(policyPath, { observe: 'response' });
  }

  submitForm(data: IDataForm): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: environment.key
      })
    };

    return this.http.post(this.apiUrl + "/registration", data, httpOptions)
      .pipe(
        map(result => ({ data: result })),
        catchError(error => {
          return this.handleError('There was an error during registration', error);
        })
      );

  }

  private handleError(operation = 'operation', error: any): Observable<never> {
    console.error(`${operation} failed:`, error);

    const errorMessage = this.getErrorMessage(error);

    return throwError(errorMessage);
  }

  private getErrorMessage(error: any): string {
    if (error.error instanceof ErrorEvent) {
      return `An error occurred: ${error.error.message}`;
    } else {
      return `Server returned code ${error.status} with message: ${error.message}`;
    }
  }
}
