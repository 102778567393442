<div [formGroup]="addressForm">
  <div class="row">
    <div class="col-9">
      <mat-form-field class="full-width" appearance="fill">
        <mat-label>Address</mat-label>
        <input matInput id="street" name="street" formControlName="street" autocomplete="off" required />
        <mat-error *ngIf="addressForm.get('street')?.invalid && (addressForm.get('street')?.touched)">
          Address is required
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-3">
      <mat-form-field class="full-width" appearance="fill">
        <mat-label>Apt, Suite, etc...</mat-label>
        <input matInput id="address2" name="address2" formControlName="address2" />
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <mat-form-field class="full-width" appearance="fill">
        <mat-label>City</mat-label>
        <input matInput id="city" name="city" formControlName="city" required />
        <mat-error *ngIf="addressForm.get('city')?.invalid && (addressForm.get('city')?.touched)">
          City is required
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-3">
      <mat-form-field class="full-width" appearance="fill">
        <mat-label>State</mat-label>
        <mat-select id="state" name="state" formControlName="state" required>
          <mat-option *ngFor="let state of states" [value]="state">{{ state }}</mat-option>
        </mat-select>
        <mat-error *ngIf="addressForm.get('state')?.invalid && (addressForm.get('state')?.touched)">
          State is required
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-3">
      <mat-form-field class="full-width" appearance="fill">
        <mat-label>Zip</mat-label>
        <input matInput id="zip" name="zip" formControlName="zip" required />
        <mat-error *ngIf="addressForm.get('zip')?.invalid && (addressForm.get('zip')?.touched)">
          Zip code is required
        </mat-error>
      </mat-form-field>
    </div>
  </div>
</div>
