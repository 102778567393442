<!-- <app-header></app-header>
<div class="content-outer-container">
  <div class="content-inner-container">
    <router-outlet />
  </div>
</div>
<app-footer></app-footer> -->

<div class="page-container">
  <app-header></app-header>
  <div class="content-outer-container">
    <div class="content-inner-container">
      <router-outlet></router-outlet>
    </div>
  </div>
  <app-footer></app-footer>
</div>
